
import React from "react";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
const Wishcard = ({
  id,
  image,
  name,
  price,
  description,
  available,
  descriptionss,
  sizeChart,
  category,
  type,
  deleteitem,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const data = {
      id,
      image,
      name,
      price,
      description,
      available,
      descriptionss,
      sizeChart,
      category,
      type,
    };
    navigate("/Product", { state: data });
  };

  return (
    <>
      <div
      key={id}
      
        className="card-head-c">
        <img
        style={{cursor: 'pointer'}} 
         onClick={handleClick}
        className="card-img-c" src={image} alt="" />

        <div className="card-con-c">
          <h1 className="pro-name-h4">{name}</h1>

          <div className="card-flex-3">
            <h1 className="pro-name-h-1">{category}</h1>
          </div>
        </div>
        <button  className="btn-delete-wishitems" onClick={()=>deleteitem(id)}>delete</button>
      </div>
      
    </>
  );
};

export default Wishcard;
