import React, { useEffect, useState } from "react";
import "./Categories.css";
import NewCarousel from "./NewCarousel";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../components/Firebase/Firebase";
import ScrollContainer from "react-indiana-drag-scroll";

const Categories = () => {
  return (
    <>
      <div className="new-div1">
        <div className="new-div2">
          <div className="new-flex-1">
            <h1 className="new-h1">Categories </h1>
            <h1 className="new-h2">See Our Collection</h1>
            {/* <span className="line-texts"></span> */}
            {/* <span className="line-texts"></span> */}
          </div>
          <div className="new-c-top">
            <ScrollContainer>
              <NewCarousel  />
            </ScrollContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
