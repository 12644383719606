import React from "react";
import './Header.css'
import Carousel from "./Carousel";
const Headerhome = () => {
  return (
    // <div className="bg-header">
    //   <h1>
    //     <span className="ban">BAN</span>
    //     <span className="sport">SPORT</span>
    //   </h1>
    // </div>
    <>
    {/* <Carousel/>  */}
    
    
    </>
  );
};

export default Headerhome;
