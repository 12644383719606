import React, { useState } from "react";
import "./CheckOut.css";
import { FiEdit3 } from "react-icons/fi";
import { useEffect } from "react";

import { auth, db } from "../../components/Firebase/Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import emailjs from "@emailjs/browser";

import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import {
  removeAllItems,
  selectBasketItems,
  selectBasketTotal,
} from "../../components/Redux/basketSlice";
import Currency from "react-currency-formatter";
import toast from "react-hot-toast";

import logo from "../../assets/images/logo/banlogo.jpg";
import Navbar from "../../components/Layout/Navbar/Navbar";


const Checkout = () => {
  const location = useLocation();
  const totalA = location.state.total;
  const totalAm = totalA + 100;

  const discount = location.state.discount;

  const [selectedState, setSelectedState] = useState("");

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const [selectedState1, setSelectedState1] = useState("");

  const handleStateChange1 = (e) => {
    setSelectedState1(e.target.value);
  };

  // An array containing all Indian states
  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
  ];

  const [name, setName] = useState();

  const [phone, setPhone] = useState("+91");
  const [pincode, setPincode] = useState();
  const [address, setAddress] = useState();
  const [email, setEmail] = useState();
  const [city, setCity] = useState();

  const [house, setHouse] = useState();

  const [userID] = useAuthState(auth);
  console.log(userID)

  const [addressdetails, setAddressdetails] = useState();

  const sendform = (e) => {
    if (
      !email ||
      !name ||
      !pincode ||
      !address ||
      !city ||
      !house ||
      !selectedState ||
      !phone
    ) {
      toast.error("Please Fill All Fields");
    } else {
      e.preventDefault();

      addDoc(collection(db, "user", userID?.uid, "Address"), {
        name,
        phone,
        pincode,
        address,
        city,
        state: selectedState,
        house,
        email,
        createdAt: serverTimestamp(),
      }).then((docRef) => {
        const washingtonRef = doc(db, "user", userID?.uid);
        updateDoc(washingtonRef, {
          defaultAddressid: docRef?.id,
        });

        setName("");
        setPhone("");
        setPincode("");
        setAddress("");

        setHouse("");
        setCity("");
        setEmail("");
        window.location.reload();
      });

      toast.success("Address add successfully");
    }
  };

  useEffect(() => {
    try {
      getDoc(doc(db, "user", userID?.uid)).then((docSnap) => {
        try {
          if (docSnap.exists()) {
            getDoc(
              doc(
                db,
                "user",
                userID?.uid,
                "Address",
                docSnap.data()?.defaultAddressid
              )
            ).then((docSnap) => {
              if (docSnap.exists()) {
                setAddressdetails(docSnap.data());
                setIsLoading(false);
              } else {
                console.log("No Document Found");
              }
            });
          } else {
            console.log("No Document Found");
          }
        } catch {
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
        }
      });
    } catch {}
  }, [userID?.uid]);

  useEffect(() => {
    if (addressdetails) {
      setName1(addressdetails.name);
      setPhone1(addressdetails.phone);
      setPincode1(addressdetails.pincode);
      setAddress1(addressdetails.address);
      setEmail1(addressdetails.email);
      setCity1(addressdetails.city);
      setSelectedState1(addressdetails.state);

      setHouse1(addressdetails.house);
    }
  }, [addressdetails]);

  const [name1, setName1] = useState();

  const [phone1, setPhone1] = useState();
  const [pincode1, setPincode1] = useState();
  const [address1, setAddress1] = useState();
  const [email1, setEmail1] = useState();
  const [city1, setCity1] = useState();

  const [house1, setHouse1] = useState();

  const dispatch = useDispatch();

  const basketTotal = useSelector(selectBasketTotal);

  const items = useSelector(selectBasketItems);
  console.log(items);

  const navigate = useNavigate();

  const membersendform = () => {
    if (!email1 || !name1 || !pincode1 || !address1 || !city1 || !phone1) {
      toast.error("Please Fill All Fields");
    } else {
      addDoc(collection(db, "orders"), {
        ProductsQty: items?.length,
        TotalAmount: totalA + 100,
        Subtotal: basketTotal,

        DeliveryPrice: 100,
        Name: name1,
        House: house1,
        Address: address1,
        Pincode: pincode1,
        State:selectedState1,
        City: city1,
        Phone: phone1,
        email: email1,
        Member: "True",

        createdAt: serverTimestamp(),
      }).then((docRef) => {
        const data = {
          orderId: docRef?.id,
          Member: "Yes",
          Name: name1,
          UserUid: userID?.uid,
          Address: address1,
          Pincode: pincode1,
          State:selectedState1,
          House: house1,
          email: email1,
          City: city1,
          Phone: phone1,

          ProductsQty: items?.length,
          TotalAmount: totalA + 100,
          Subtotal: basketTotal,
        };

        emailjs.send(
          "service_70wagw2",
          "template_bguyg1p",
          data,
          "WrISjk7XzVnx5DZ5a"
        );

        for (let i = 0; i < items.length; i++) {
          addDoc(collection(db, "orders", docRef?.id, "Product"), {
            ProductName: items[i]?.productname,
            ProductPrice: items[i]?.price,
            ProductImage: items[i]?.image,
            type: items[i]?.type,
            category: items[i].category,
            id: items[i].id,
            size: items[i]?.size,
            DeliveryPrice: 100,
          });

          addDoc(collection(db, "user", userID?.uid, "Orders"), {
            ProductName: items[i]?.productname,
            ProductPrice: items[i]?.price,
            ProductImage: items[i]?.image,
            ProductQty: items?.length,
            Size: items[i]?.size,
            type: items[i]?.type,
            category: items[i].category,
            id: items[i].id,
            DeliveryPrice: 100,
            createdAt: serverTimestamp(),
          });
        }

        toast.success("Order Confirmed");
        dispatch(removeAllItems());
        navigate("/");
        setName1("");
        setPhone1("");
        setPincode1("");
        setAddress1("");

        setCity1("");
        setEmail1("");
      });
    }
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async () => {
    try {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("You are offline... Failed to load Razorpay SDK");





      }

      const options = {
        key: "rzp_test_uHaNy8zOYv0KcN",
        image: logo,
        currency: "INR",
        amount: totalAm * 100,
        name: "FUNC",
        description: "Thanks for purchasing",
        
        theme: {
          color: "#000000",
        },
        handler: async (response) => {
          try {
            // Check if payment was successful
            if (response.razorpay_payment_id) {
              toast.success("Payment Successfully");

              // Add the order to Firestore
              const docRef = await addDoc(collection(db, "orders"), {
                ProductsQty: items?.length,
                TotalAmount: totalA + 100,
                Subtotal: basketTotal,

                DeliveryPrice: 100,
                Name: name1,
                House: house1,
                Address: address1,
                Pincode: pincode1,
                State:selectedState1,

                City: city1,
                Phone: phone1,
                email: email1,
                Member: "True",

                createdAt: serverTimestamp(),
              });

              const data = {
                orderId: docRef?.id,
                Member: "Yes",
                Name: name1,
                UserUid: userID?.uid,
                Address: address1,
                Pincode: pincode1,
                State:selectedState1,

                House: house1,
                email: email1,
                City: city1,
                Phone: phone1,

                ProductsQty: items?.length,
                TotalAmount: totalA + 100,
                Subtotal: basketTotal,
              };

              // Send email
              await emailjs.send(
                "service_70wagw2",
                "template_bguyg1p",
                data,
                "WrISjk7XzVnx5DZ5a"
              );

              // Add products to the order and user's orders
              for (let i = 0; i < items.length; i++) {
                addDoc(collection(db, "orders", docRef?.id, "Product"), {
                  ProductName: items[i]?.productname,
                  ProductPrice: items[i]?.price,
                  ProductImage: items[i]?.image,
                  type: items[i]?.type,
                  category: items[i].category,
                  id: items[i].id,

                  size: items[i]?.size,
                  DeliveryPrice: 100,
                });

                addDoc(collection(db, "user", userID?.uid, "Orders"), {
                  ProductName: items[i]?.productname,
                  ProductPrice: items[i]?.price,
                  ProductImage: items[i]?.image,
                  ProductQty: items?.length,
                  Size: items[i]?.size,
                  type: items[i]?.type,
                  category: items[i].category,
                  id: items[i].id,
                  DeliveryPrice: 100,
                  createdAt: serverTimestamp(),
                });
              }

              toast.success("Order Confirmed");
              dispatch(removeAllItems());
              navigate("/");
              setName1("");
              setPhone1("");
              setPincode1("");
              setAddress1("");

              setCity1("");
              setEmail1("");
            } else {
              // Handle payment failure
              toast.error("Payment Failed");
            }
          } catch (error) {
            toast.error(error.message);
          }
        },

        prefill: {
          name: userID?.name,
          email: userID?.email,
          contact: userID?.phone,
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      toast.error(error.message);
    }
  };


  console.log(addressdetails)

  const [isLoading, setIsLoading] = useState(true);

  if (isLoading) {
    return (
      <>
        <div className="loader-sp">
          <BeatLoader size={15} color={"black"} loading={isLoading} />
        </div>
      </>
    );
  }

  return (
    <>
    <Navbar/>
    
    <div className="chekout-d1">
      <h1 className="check-heading">Checkout</h1>
      <div className="checkout-d2">
        <div className="checkout-d3">
          {addressdetails ? (
            <div className="lh-checkout">
              <div className="lh-check-1">
                <div className="lh-check-2">
                  <div className="lh-check-3">
                    <h1>My information</h1>
                    <Link to={"/Account"}>
                      <FiEdit3 />
                    </Link>
                  </div>
                  <div className="lh-check-4">
                    <span>{name1}</span>
                    <span>{email1}</span>
                  </div>
                </div>
              </div>
              <div className="lh-check-5">
                <div className="lh-c1">
                  <div className="lh-c4">
                    <div className="lh-c2">
                      <h1 className="bill-dd-h1">Billing address</h1>
                    </div>
                    <div className="lh-c3">
                      <span>Enter your billing address</span>
                    </div>
                    <div className="lh-c5">
                      <span>Registered No</span>
                      <span>{userID?.phoneNumber}</span>
                    </div>
                    <div className="form-group-lh-checkout">
                      <div className="ih-c6">
                        <div className="lh-form-in">
                          <label>
                            Contact No <span>*</span>
                          </label>
                          <input
                            type="text"
                            onChange={(e) => setPhone1(e.target.value)}
                            value={phone1}
                            required
                          />
                        </div>
                      </div>

                      <div className="lh-c7">
                        <div className="ih-c8">
                          <div className="lh-form-in">
                            <label>
                              Address<span>*</span>
                            </label>
                            <input
                              type="text"
                              name="cardNumber"
                              onChange={(e) => setAddress1(e.target.value)}
                              value={address1}
                              required
                            />
                          </div>
                        </div>
                        <div className="ih-c8">
                          <div className="lh-form-in">
                            <label>
                              House / Flat No<span>*</span>
                            </label>
                            <input
                              type="text"
                              name="cardNumber"
                              onChange={(e) => setHouse1(e.target.value)}
                              value={house1}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="lh-c7">
                        <div className="ih-c8">
                          <div className="lh-form-in">
                            <label>
                              Town/City <span>*</span>
                            </label>
                            <input
                              type="text"
                              name="cardNumber"
                              onChange={(e) => setCity1(e.target.value)}
                              value={city1}
                              required
                            />
                          </div>
                        </div>
                        <div className="ih-c8">
                          <div className="lh-form-in">
                            <label>
                              Pincode <span>*</span>
                            </label>
                            <input
                              type="text"
                              name="cardNumber"
                              onChange={(e) => setPincode1(e.target.value)}
                              value={pincode1}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="ih-c6">
                        <div className="lh-form-in">
                          <label>
                            State <span>*</span>
                          </label>
                          <select
                            id="stateSelect"
                            value={selectedState1}
                            onChange={handleStateChange1}
                          >
                            <option value="">Select State</option>
                            {indianStates.map((state, index) => (
                              <option key={index} value={state}>
                                {state}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {/* <div className="lh-c8">
                      <button>Save</button>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="lh-checkout">
              <div className="lh-check-5">
                <div className="lh-c1">
                  <div className="lh-c4">
                    <div className="lh-c2">
                      <h1 className="bill-dd-h1">Billing address</h1>
                    </div>
                    <div className="lh-c3">
                      <span>Enter your billing address</span>
                    </div>
                    <div className="lh-c5">
                      <span>Registered No</span>
                      <span>{userID?.phoneNumber}</span>
                    </div>
                    <div className="form-group-lh-checkout">
                      <div className="lh-c7">
                        <div className="ih-c8">
                          <div className="lh-form-in">
                            <label>
                              Name <span>*</span>
                            </label>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              onChange={(e) => setName(e.target.value)}
                              value={name}
                              required
                            />
                          </div>
                        </div>
                        <div className="ih-c8">
                          <div className="lh-form-in">
                            <label>
                              Email <span>*</span>
                            </label>
                            <input
                              type="email"
                              id="email"
                              name="email"
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="ih-c6">
                        <div className="lh-form-in">
                          <label>
                            Contact No <span>*</span>
                          </label>
                          <input
                            type="tel"
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                            required
                          />
                        </div>
                      </div>
                      <div className="lh-c7">
                        <div className="ih-c8">
                          <div className="lh-form-in">
                            <label>
                              Address<span>*</span>
                            </label>
                            <input
                              type="text"
                              onChange={(e) => setAddress(e.target.value)}
                              value={address}
                              required
                            />
                          </div>
                        </div>
                        <div className="ih-c8">
                          <div className="lh-form-in">
                            <label>
                              House / Flat No* <span>*</span>
                            </label>
                            <input
                              type="text"
                              onChange={(e) => setHouse(e.target.value)}
                              value={house}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="lh-c7">
                        <div className="ih-c8">
                          <div className="lh-form-in">
                            <label>
                              Town/City <span>*</span>
                            </label>
                            <input
                              type="text"
                              onChange={(e) => setCity(e.target.value)}
                              value={city}
                              required
                            />
                          </div>
                        </div>
                        <div className="ih-c8">
                          <div className="lh-form-in">
                            <label>
                              Pincode <span>*</span>
                            </label>
                            <input
                              type="number"
                              name="cardNumber"
                              onChange={(e) => setPincode(e.target.value)}
                              value={pincode}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="ih-c6">
                        <div className="lh-form-in">
                          <label>
                            State <span>*</span>
                          </label>
                          <select
                            id="stateSelect"
                            value={selectedState}
                            onChange={handleStateChange}
                          >
                            <option value="">Select State</option>
                            {indianStates.map((state, index) => (
                              <option key={index} value={state}>
                                {state}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="lh-c8">
                        <button onClick={sendform}>Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="rh-checkout">
            <div className="check-d4 ">
              <div className="cart-d5">
                {discount && (
                  <div className="rh-cart-t">
                    <div className="rh-cart-t1">
                      <span>Discounts</span>
                      <span>{discount}%</span>
                    </div>
                  </div>
                )}

                <div className="rh-cart-t4">
                  <span>Order value</span>
                  <span>
                    <Currency quantity={basketTotal + 100} currency="INR" />
                  </span>
                </div>
                <div className="rh-cart-t4">
                  <span>Delivery</span>
                  <Currency quantity={100} currency="INR" />
                </div>
                <div className="rh-cart-t5">
                  <span>Total</span>
                  <span>
                    <Currency quantity={totalA + 100} currency="INR" />
                  </span>
                </div>

                <p className="rh-c-p1">
                  By continuing, you agree to <br />
                  <span>Bansport General Terms and Conditions.</span>
                </p>
                <p className="rh-c-p1">
                  We will process your personal data in accordance with the Bansport
                  <br />
                  <span>Privacy Notice.</span>
                </p>
{addressdetails? 

<div className="rh-checkout-t6">
<button onClick={() => displayRazorpay()}>Almost Done</button>
</div>
:
<div className="rh-checkout-t67">
<button >Almost Done</button>
</div>
}
             
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
 
  );
};

export default Checkout;
