import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, FreeMode } from "swiper/modules";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../components/Firebase/Firebase";
import Swipercard from "./Swipercard";

const RelatedSwiper = ({category,refreshPage}) => {



  const [featured, setfeatured] = useState([]);

  useEffect(() => {
    const q = collection(db, "Products", "Top", "PumpCover");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setfeatured(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [featured2, setfeatured2] = useState([]);

  useEffect(() => {
    const q = collection(db, "Products", "Top", "MuscleTanks");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setfeatured2(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [featured3, setfeatured3] = useState([]);

  useEffect(() => {
    const q = collection(db, "Products", "Top", "HoodiesTracksuit");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setfeatured3(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [featured4, setfeatured4] = useState([]);

  useEffect(() => {
    const q = collection(db, "Products", "Bottom", "Shorts");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setfeatured4(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);
  const [featured5, setfeatured5] = useState([]);

  useEffect(() => {
    const q = collection(db, "Products", "Bottom", "QuadShorts");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setfeatured5(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const combine = (featured, featured2, featured3, featured4, featured5) => {
    return [
      ...featured,
      ...featured2,
      ...featured3,
      ...featured4,
      ...featured5,
    ];
  };
  const result = combine(
    featured,
    featured2,
    featured3,
    featured4,
    featured5
  ).filter((item) => item.Category === category);

  return (
    <>
      <div className="carousel-div12">
      {Array.isArray(result)
            ? result.map((item, key) => {
                return (
                  <>
         
                      <Swipercard
                        id={item.id}
                        name={item.Name}
                        image={item.Image}
                        price={item.Price}
                        description={item.Description}
                        descriptionss={item.Descriptionss}
                        available={item.Available}
                        sizeChart={item.sizeChart}
                        category={item.Category}
                        type={item.type}
                        refreshPage={refreshPage}
                      />
                
                  </>
                );
              })
            : null}
      </div>
    </>
  );
};

export default RelatedSwiper;
