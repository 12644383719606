import React from "react";
import { CiDeliveryTruck } from "react-icons/ci";
import { IoTimeOutline } from "react-icons/io5";
import { MdCompareArrows } from "react-icons/md";
import { RiSecurePaymentLine } from "react-icons/ri";
import './HomeComponent.css'
const Home4 = () => {
  const data = [
    { id: 1, title: "Fast Delivery", icon: CiDeliveryTruck },
    { id: 2, title: "All orders placed on time limit", icon: IoTimeOutline },
    { id: 3, title: "Delivery time 1-3 Business days ", icon: MdCompareArrows },
    { id: 4, title: "Secure Payments", icon: RiSecurePaymentLine },

    // Add more objects as needed
  ];

  return (
    <div className="home-c1">
      <div className="home-c2">
        <div className="home-cd2">
          {data.map((item) => (
            <>
              <div className="home-cd1">
                <item.icon  className="icon-cd2" />

                <span>{item.title}</span>
              </div>
            </>
          ))}
        </div>

        <div></div>
      </div>
    </div>
  );
};

export default Home4;
