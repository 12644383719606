import React from "react";
import { useNavigate } from "react-router-dom";
import Currency from "react-currency-formatter";

const Swipercard = ({
  id,
  image,
  name,
  price,
  description,
  available,
  descriptionss,
  sizeChart,
  category,
  type,
  psize,
  refreshPage,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const data = {
      id,
      image,
      name,
      price,
      description,
      available,
      descriptionss,
      sizeChart,
      category,
      type,
      psize,
    };
    navigate("/Product", { state: data });
    refreshPage();
  };

  return (
    <>
      <div className="new-div5">
        <div className="new-div6" onClick={handleClick}>
          <div className="card-relative-pro">
            {/* <div className="p-a-card-f1 ">
                    <h1 className="q-v-h2">Quick View</h1>
                  </div> */}
            <img className="slider-img-2" src={image} alt="" />
          </div>
          <div className="card-pro-content">
            <h1 className="pro-name-h5">{name}</h1>
            {/* <p className="pro-dec1">Lorem ipsum dolor sit amet. </p> */}
            <div className="card-flex-1">
              <h1 className="pro-price-h1">
                <Currency quantity={price} currency="INR" />
              </h1>

              {/* <h1 className="pro-offer-c">(30% off)</h1> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Swipercard;
