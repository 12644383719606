import React, { useEffect, useState } from "react";

import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../components/Firebase/Firebase";
import ScrollContainer from "react-indiana-drag-scroll";
import HomeCard2 from "./HomeCard2";

const Home2 = ({ filterresult3 }) => {
  return (
    <>
      <div className="new-div1-1">
        <div className="new-div2">
          <div className="new-flex-1">
            <h1 className="new-h1">Hot Seller </h1>
            <h1 className="new-h2">See Our Collection</h1>
            {/* <span className="line-texts"></span> */}
            {/* <span className="line-texts"></span> */}
          </div>
          <div className="new-c-top">
            <ScrollContainer>
              {filterresult3.map((item) => (
                <HomeCard2
                  id={item.id}
                  Homeimg={item.Homeimg}
                  name={item.Name}
                  image={item.Image}
                  price={item.Price}
                  description={item.Description}
                  descriptionss={item.Descriptionss}
                  available={item.Available}
                  sizeChart={item.sizeChart}
                  category={item.Category}
                  type={item.type}
                />
              ))}
            </ScrollContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home2;
