import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { useNavigate } from "react-router-dom";
// import required modules

const Carousel = () => {

  const navigate = useNavigate ();


  // const handleSlideClick = (event) => {
  
  //   console.log("Slide clicked!");

  // };
  return (
    <div className="hero-slider-area">
      <Swiper
        speed={2000}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          bulletActiveClass: "swiper-pagination-bullet-active",
          renderBullet: function (index, className) {
            return (
              '<span class="' +
              className +
              '" style="background-color: white;"></span>'
            );
          },
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        
      >
        <SwiperSlide onClick={()=> navigate('Product')}>
          <img
          
            className="slider-img-carousel"
            src={require("../../assets/images/slider-bg.jpg")}
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="slider-img-carousel"
            src={require("../../assets/images/bg-home.jpg")}
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="slider-img-carousel"
            src={require("../../assets/images/bg-home.jpg")}
            alt=""
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Carousel;
