import firebase from 'firebase/compat/app';
import 'firebase/compat/database'
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getFirestore } from "firebase/firestore";
import "firebase/firestore";
import { getStorage } from "firebase/storage";

import {

  getAuth,

  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

const app = firebase.initializeApp({
  apiKey: "AIzaSyBlUJmU0dvWF9N7v_dsf1pR3ZEY1e6w40k",
  authDomain: "bansport-app.firebaseapp.com",
  projectId: "bansport-app",
  storageBucket: "bansport-app.appspot.com",
  messagingSenderId: "447389345905",
  appId: "1:447389345905:web:f6084d95292d97a82bd3d0",
  measurementId: "G-J371R4N874"
});

export const db = getFirestore(app);
export const storage = getStorage(app);
const auth = firebase.auth()

export default app

export { auth  };
