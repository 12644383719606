import React from "react";
import { useNavigate } from "react-router-dom";

const HomeCard3 = ({
  id,
  image,
  name,
  price,
  description,
  available,
  descriptionss,
  sizeChart,
  category,
  Homeimg,
  type,
}) => {



    const navigate = useNavigate();

    const handleClick = () => {
      const data = {
        id,
        image,
        name,
        price,
        description,
        available,
        descriptionss,
        sizeChart,
        category,
        type,
      };
      navigate("/Product", { state: data });
    };

  return (
    <div onClick={handleClick} className="home-c4">
      <img src={Homeimg} alt="" />
    </div>
  );
};

export default HomeCard3;
