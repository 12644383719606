import React from 'react'
import Navbar from '../../components/Layout/Navbar/Navbar'


const Term = () => {
  return (
    <>
    <Navbar/>
    <div className="new-div1">
      <div className="new-div2">
        <h1 className="headig-sh">Terms Conditions</h1>
        <div className="rp-ontent2">
          <p>This website is operated by Bansprt.. Throughout the site, the terms “we”, “us” and “our” refer to bansprt. Swed offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
          

          <p>
          By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.
          </p>
      

          <h1 className="headig-sh2">SECTION 1 – ONLINE STORE TERMS</h1>

          <p>
          By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.
          </p>


          <p>
          You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).
          </p>
          <p>
          You must not transmit any worms or viruses or any code of a destructive nature.
          </p>
            <p>
            A breach or violation of any of the Terms will result in an immediate termination of your Services.
            </p>







            
    
    
        </div>
      </div>
    </div>
  </>
  )
}

export default Term