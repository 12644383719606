import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home/Home";
import ScrollTop from "../src/components/Scroll/ScrollTop";
import ScrollToTop from "../src/components/Scroll/ScrollToTop";
import { Toaster } from "react-hot-toast";
import Footer from "./components/Layout/Footer/Footer";
import Product from "./Pages/Product/Product";
import Cart from "./Pages/Cart/Cart";
import CheckOut from "./Pages/Checkout/CheckOut";
import Category from "./Pages/Category/Category";
import Wishlist from "./Pages/Wishlist/Wishlist";
import Signin from "./Pages/Login/Signin";
import Signup from "./Pages/Login/Signup";
import Account from "./Pages/Account/Account";
import { Provider } from "react-redux";
import { store } from "./Store";
import Test from "./Pages/Test";

import "./App.scss";
import Privacy from "./Pages/Policy/Privacy";
import Term from "./Pages/Policy/Term";
import Refund from "./Pages/Policy/Refund";
import Contactus from "./Pages/ContactUs/Contactus";

const App = () => {
  return (
    <>
      <Provider store={store}>
        <Router>
          <Toaster />

          <ScrollTop />
          <ScrollToTop />

          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/Product" element={<Product />} />
            <Route exact path="/Cart" element={<Cart />} />
            <Route exact path="/CheckOut" element={<CheckOut />} />
            <Route exact path="/Category" element={<Category />} />
            <Route exact path="/Wishlist" element={<Wishlist />} />
            <Route exact path="/Signin" element={<Signin />} />
            <Route exact path="/Signup" element={<Signup />} />
            <Route exact path="/Account" element={<Account />} />
            <Route exact path="/Privacy-Policy" element={<Privacy />} />
            <Route exact path="/Terms-Conditions" element={<Term />} />

            <Route exact path="/Refund-Shipping-Policy" element={<Refund />} />
            <Route exact path="/Contact-us" element={<Contactus />} />


            <Route exact path="/Test" element={<Test />} />
          </Routes>
          <Footer />
        </Router>
      </Provider>
    </>
  );
};

export default App;
