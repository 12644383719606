import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, FreeMode } from "swiper/modules";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../components/Firebase/Firebase";
import { BeatLoader } from "react-spinners";
const HomeCard2 = ({
  id,
  image,
  name,
  price,
  description,
  available,
  descriptionss,
  sizeChart,
  category,
  Homeimg,
  type,
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    const data = {
      id,
      image,
      name,
      price,
      description,
      available,
      descriptionss,
      sizeChart,
      category,
      type,
    };
    navigate("/Product", { state: data });
  };
  return (
    <>
      <div className="carousel-div1">
        <div className="new-div5">
          <div className="new-div6" onClick={handleClick}>
            <img className="slider-img-1" src={image} alt="" />
            <h1 className="slide-heading">{name}</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeCard2;
