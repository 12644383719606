import React from "react";

import './Policy.css'
import Navbar from "../../components/Layout/Navbar/Navbar";
const Refund = () => {
  return (
    <>
      <Navbar/>
      <div className="new-div1">
        <div className="new-div2">
          <h1 className="headig-sh">Cancellation and Refund Policy</h1> 
          <div className="rp-ontent">
            <p>Cancellation and Refund Policy for BAN SPORT Clothing Brand:</p>
            <p>
            At BAN SPORT, we strive to provide our customers with high-quality clothing products that exceed their expectations. If for any reason you are not completely satisfied with your purchase, we offer a flexible cancellation and refund policy to ensure that you have a hassle-free shopping experience. Please read the following policy carefully:
            </p>
            <p>Cancellation Policy:</p>
            <p>If you wish to cancel your order, you must do so within 24 hours of placing the order. Once the order has been processed and shipped, we cannot cancel it. To cancel your order, please contact our customer service team via email or phone and provide them with your order details.</p>
            <p>Refund Policy:</p>
            <p>We offer a full refund on all items returned in their original condition within 14 days of the purchase date. If you would like to return an item, please follow these steps:</p>
            <p>Contact our customer service team and provide them with your order details.</p>
            <p>Pack the item(s) securely in the original packaging, if possible, and include all paperwork, tags, and accessories.</p>
            <p>Ship the item(s) to the address provided by our customer service team. Please note that the customer is responsible for the return shipping costs.</p>
            <p>Once we receive the returned item(s), we will inspect them to ensure that they are in their original condition.</p>
            <p>After the inspection, we will issue a refund to the original payment method. Please allow up to 10 business days for the refund to appear on your account.</p>
            <p>Please note that the following items are not eligible for a refund:</p>
            <p>Items that have been worn, washed, or damaged</p>
            <p>Items that are not in their original condition</p>
            <p>Items that are not in their original condition</p>
            <p>Exchanges:</p>
            <p>We do not offer direct exchanges. If you would like to exchange an item, please follow the return process and place a new order for the desired item.</p>
            <p>BAN SPORT reserves the right to amend this policy at any time without notice. If you have any questions about this policy or your order, please contact our customer service team.</p>
            <h1 className="headig-sh1">Shipping Policy</h1>
            <p>Orders will be processed and shipped within 4-7 business days of purchase. We use standard shipping services and our estimated delivery times are as follows:</p>
            <p>Minimum Shipping Days: 4 days</p>
            <p>Maximum Shipping Days: 7 days</p>
            <p>Please note that these delivery times are estimated and may vary depending on the shipping carrier and destination.</p>
            <p>Shipping fees are based on the weight and destination of your order and will be calculated at checkout.</p>
            <p>We do not offer expedited shipping options at this time.</p>
            <p>Once your order has been shipped, you will receive a confirmation with tracking information. You can use this information to track your order’s delivery status.</p>
            <p>Please ensure that your shipping address is correct and complete to avoid any delays or misdeliveries.</p>
            <p>If you have any questions or concerns regarding your order’s shipping status, please contact us at: bansport74@gmail.com</p>
            <p>Thank you for choosing Bansport and we hope you enjoy your purchase</p>
         


                      </div>
        </div>
      </div>
    </>
  );
};

export default Refund;
