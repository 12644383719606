import React from "react";
import "./Footer.css";
import {  AiOutlineInstagram } from "react-icons/ai";
import {BsFacebook} from "react-icons/bs";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="head-footer">
      <div className="footer-bg">
        <div className="flex-footer-1">
          <div className="left-h-footer">
        <img className="footer-logo-img" src={require('../../../assets/images/logo/banlogo.jpg')} alt="" />
            <p className="footer-para">
            We work with a passion of taking challenges and creating new ones in advertising sector.
            </p>
          </div>
          <div>
            <div>
              <h1 className="footer-h1">Links</h1>
            </div>
            <div>
              <ul className="ul-footer">
              <Link to={"/Refund-Shipping-Policy"}>
                  <li>Refund Shipping Policy</li>
                </Link>
              <Link to={"/Privacy-Policy"}>
                  <li>Privacy Policy</li>
                </Link>
                <Link to={"/Terms-Conditions"}>
                  <li>Terms Conditions</li>
                </Link>
                <Link to={"/Contact-us"}>
                  <li>Contact Us</li>
                </Link>
               
               
                
              </ul>
            </div>
          </div>
          <div>
            <div>
              <h1 className="footer-h1">Contact</h1>
            </div>
            <div>
              <ul className="ul-footer">
                <li className="email-li">Email Address</li>

                <li>bansport74@gmail.com</li>

                <div
                  className="div-footer-icons
"
                >
                  <a
                    href="https://www.instagram.com/bansportofficial/?igshid=MzRlODBiNWFlZA%3D%3D"
                    target="_blank"
                  >
                    <div>
                      <AiOutlineInstagram
                        className="icon-footer"
                        size={20}
                        color="white"
                      />
                    </div>
                  </a>
                  <a
                    href="https://www.facebook.com/banofficials7d4/"
                    target="_blank"
                  >
                    <div>
                      <BsFacebook
                        className="icon-footer"
                        size={20}
                        color="white"
                      />
                    </div>
                  </a>
                  {/* <div>
                    <AiFillYoutube
                      className="icon-footer"
                      size={20}
                      color="white"
                    />
                  </div> */}
                </div>
              </ul>
            </div>
          </div>
        
        </div>
      </div>
      <div>
        <h1 className=" text-copyright">
          © 2023 Bansport.All Rights Reserved
        </h1>
      </div>
    </div>
  );
};

export default Footer;
