import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, FreeMode } from "swiper/modules";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../components/Firebase/Firebase";
import { BeatLoader } from "react-spinners";
const NewCarousel = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [product, setproduct] = useState([]);

  useEffect(() => {
    const todosref = collection(db, "Categories");
    const q = query(todosref, orderBy("num", "asc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setproduct(cities);
      setIsLoading(false)
    
    });

    return () => {
      unsubscribe();
    };
  }, []);



  if (isLoading) {
    return (
      <div className="loader-sp">
        <BeatLoader size={15} color={"black"} loading={isLoading} />
      </div>
    );
  }



  return (
    <>
      <div className="carousel-div1">
        {Array.isArray(product)
          ? product.map((item, key) => {
              return (
                <>
                  <div className="new-div5">
                    <div
                      className="new-div6"
                      onClick={() =>
                        navigate("/Category", { state: { product: item } })
                      }
                    >
                      <img className="slider-img-1" src={item.img2} alt="" />
                      <h1 className="slide-heading">{item.PName}</h1>
                    </div>
                  </div>
                </>
              );
            })
          : null}
      </div>
    </>
  );
};

export default NewCarousel;
