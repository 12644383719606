import React, { useState, useEffect } from "react";
import "./Home.css"; // I assumed a CSS file extension, please adjust if different
import Navbar from "../../components/Layout/Navbar/Navbar";
import Headerhome from "../../components/HeaderHome/Headerhome";
import Categories from "../../components/Categories/Categories";
import Deals from "../../components/Deals/Deals";
import NewArrivals from "../../components/NewArrivals/NewArrivals";
import Carousel from "../../components/HeaderHome/Carousel";
import Products from "../../components/Products/Products";

import Loader from "../../components/Loader/Loader";
import { BeatLoader } from "react-spinners";
import Home3 from "../../components/HomeComponent/Home3";
import Home4 from "../../components/HomeComponent/Home4";
import Home5 from "../../components/HomeComponent/Home5";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../components/Firebase/Firebase";
import Home2 from "../../components/HomeComponent/Home2";

const Home = () => {
  const [featured, setfeatured] = useState([]);

  useEffect(() => {
    const q = collection(db, "Products", "Top", "PumpCover");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });

      setfeatured(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [featured2, setfeatured2] = useState([]);

  useEffect(() => {
    const q = collection(db, "Products", "Top", "MuscleTanks");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setfeatured2(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [featured3, setfeatured3] = useState([]);

  useEffect(() => {
    const q = collection(db, "Products", "Top", "HoodiesTracksuit");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });

      setfeatured3(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [featured4, setfeatured4] = useState([]);

  useEffect(() => {
    const q = collection(db, "Products", "Bottom", "Shorts");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setfeatured4(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);
  const [featured5, setfeatured5] = useState([]);

  useEffect(() => {
    const q = collection(db, "Products", "Bottom", "QuadShorts");
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setfeatured5(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [result, setResult] = useState([]);

  useEffect(() => {
    // Combine the arrays whenever any of them changes
    const combinedArray = [
      ...featured,
      ...featured2,
      ...featured3,
      ...featured4,
      ...featured5,
    ];
    setResult(combinedArray);
  }, [featured, featured2, featured3, featured4, featured5]);


  const filterresult1 = result.filter((item) => item.showhome === true);
  

  const filterresult2 = result.filter((item) => item?.showhome2 === true);
  
  const filterresult3 = result.filter((item) => item?.Hotseller === true);

  return (
    <>
      <Navbar />
      <Carousel />
      <Headerhome />
      <Home2 filterresult3={filterresult3} />
      <Categories />

      <Home3 filterresult1={filterresult1} />
      <Home4 />
      <Home5  filterresult={filterresult2}/>
      {/* <Deals />
      <NewArrivals /> */}
    </>
  );
};

export default Home;
