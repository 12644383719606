import { collection, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../Firebase/Firebase";
import HomeCard4 from "./HomeCard4";

const Home5 = ({ filterresult }) => {
  return (
    <div>
      <div className="bg-h-c1">
        <div  className="h5-card-d1">
        {filterresult.map((item) => (
          <>
            <HomeCard4
              id={item.id}
              Homeimg={item.Homeimg}
              name={item.Name}
              image={item.Image}
              price={item.Price}
              description={item.Description}
              descriptionss={item.Descriptionss}
              available={item.Available}
              sizeChart={item.sizeChart}
              category={item.Category}
              type={item.type}
            />
          </>
        ))}
        </div>
   
      </div>
    </div>
  );
};

export default Home5;
