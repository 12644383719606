import React, { useEffect, useState } from "react";
import "./HomeComponent.css";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../Firebase/Firebase";
import HomeCard3 from "./HomeCard3";
const Home3 = ({ filterresult1 }) => {
  return (
    <div className="home-c1">
      <div className="home-c2">
        <div className="new-flex-1">
          <h1 className="new-h1">BEYOND LIFESTYLE </h1>
          <h1 className="new-h2">BODYBUILDING CLUB</h1>
        </div>

        <div className="home-c3">
          {filterresult1.map((item) => (
            <>
              {/* <div className="home-c4">
                <img src={item.Homeimg} alt="" />
              </div> */}

              <HomeCard3
                id={item.id}
                Homeimg={item.Homeimg}
                name={item.Name}
                image={item.Image}
                price={item.Price}
                description={item.Description}
                descriptionss={item.Descriptionss}
                available={item.Available}
                sizeChart={item.sizeChart}
                category={item.Category}
                type={item.type}
              />
            </>
          ))}
        </div>

        {/* <div className="home-c4">
            <img
              src="https://bansport.in/wp-content/uploads/2023/05/IMG_3297-2-scaled.jpg"
              alt=""
            />
          </div> */}
      </div>
    </div>
  );
};

export default Home3;
