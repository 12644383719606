import React from "react";
import { useNavigate } from "react-router-dom";

const HomeCard4 = ({
  id,
  image,
  name,
  price,
  description,
  available,
  descriptionss,
  sizeChart,
  category,
  Homeimg,
  type,
}) => {


    const navigate = useNavigate();

    const handleClick = () => {
      const data = {
        id,
        image,
        name,
        price,
        description,
        available,
        descriptionss,
        sizeChart,
        category,
        type,
      };
      navigate("/Product", { state: data });
    };


  return (
    <div  onClick={handleClick}>
      <div className="h5-card-d2">
        <img
          className="img-card-h5"
          src={Homeimg}
          alt=""
        />
      </div>
      {/* <div className="h5-card-d2">
        <img
          className="img-card-h5"
          src="https://bansport.in/wp-content/uploads/2022/04/bodyy.jpg"
          alt=""
        />
      </div>
      <div className="h5-card-d2">
        <img
          className="img-card-h5"
          src="https://bansport.in/wp-content/uploads/2022/04/body.jpg"
          alt=""
        />
      </div> */}
    </div>
  );
};

export default HomeCard4;
