import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import "./Login.css";

import {
  RecaptchaVerifier,
  onAuthStateChanged,
  signInWithPhoneNumber,
  signOut,
} from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import { auth, db } from "../../components/Firebase/Firebase";
import { CgSpinner } from "react-icons/cg";
import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { BeatLoader } from "react-spinners";
import Navbar from "../../components/Layout/Navbar/Navbar";


const Login = () => {
  const location = useLocation();
  const item = location.state;

  const navigate = useNavigate();

  const handleotpNumberChange = (e) => {
    // Remove any non-numeric characters from the input
    const otp = e.target.value.replace(/\D/g, "");

    // Check if the input is exactly 10 digits long
    if (otp.length === 6) {
      setOtp(otp);
    } else if (otp.length < 6) {
      // If the input is less than 10 digits, update the state
      setOtp(otp);
    }
    // You can also add an else condition to handle cases where inputNumber.length > 10.
  };

  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("+91");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  }

  function onSignup() {
    if (!ph) {
      toast.error("Fill the requested field ");
    } else {
      setLoading(true);
      onCaptchVerify();

      const appVerifier = window.recaptchaVerifier;

      const formatPh = "+" + ph;

      signInWithPhoneNumber(auth, formatPh, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setLoading();
          setShowOTP(true);

          toast.success("OTP sended successfully!");
        })

        .catch((error) => {
          // toast.error(error);
          console.log(error);
          setLoading(false);
        });
    }
  }
  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res);
        getDoc(doc(db, "user", res.user.uid)).then((docSnap) => {
          if (docSnap.exists()) {
            updateDoc(doc(db, "user", res.user.uid), {
              lastLogin: serverTimestamp(),
            });
          } else {
            setDoc(doc(db, "user", res.user.uid), {
              uid: res.user.uid,
              Phone: ph,
              createdAt: serverTimestamp(),
              loginPermission: true,
              lastLogin: serverTimestamp(),
            });
          }
        });

        setUser(res.user);
        setLoading(false);
      })
      .catch((err) => {
        switch (err.code) {
          case "auth/invalid-verification-code":
            toast.error("Invalid OTP");
            break;
        }
        // console.log(err);
        setLoading(false);
      });
  }
  // function onOTPVerify1() {
  //   setLoading(true);
  //   window.confirmationResult
  //     .confirm(otp)
  //     .then(async (res) => {
  //       console.log(res);
  //       setUser(res.user);
  //       setLoading(false);
  //       const user = res.user;

  //       setDoc(doc(db, "user", user.uid), {
  //         uid: user.uid,
  //         Phone: ph,
  //         createdAt: serverTimestamp(),
  //       });
  //       toast.success("Login Success");
  //     })

  //     .catch((err) => {
  //       switch (err.code) {
  //         case "auth/invalid-verification-code":
  //           toast.error("Invalid OTP");
  //           console.log(err);
  //           setOtp("");
  //           break;
  //       }
  //       // console.log(err);
  //       setLoading(false);
  //     });
  // }

  // function onOTPVerify() {
  //   setLoading(true);
  //   window.confirmationResult
  //     .confirm(otp)
  //     .then(async (res) => {
  //       console.log(res);
  //       setUser(res.user);
  //       setLoading(false);
  //       const user = res.user;

  //       setDoc(doc(db, "user", user.uid), {
  //         uid: user.uid,
  //         Phone: ph,
  //         createdAt: serverTimestamp(),
  //       });
  //       toast.success("Login Success");
  //     })

  //     .catch((err) => {
  //       toast.error(err);
  //       console.log(err);
  //       setLoading(false);
  //     });
  // }

  const handleSignOut = () => {
    auth.signOut().catch((error) => alert(error.message));
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getDoc(doc(db, "user", user.uid)).then((docSnap) => {
          if (docSnap.exists()) {
            setUser(docSnap.data());
          } else {
            console.log("No Document Found");
          }
        });
      } else {
        console.log("no user");

        setUser();
      }
    });
  }, []);
  useEffect(() => {
    const initializeRecaptcha = async () => {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {
              onSignup();
            },
            "expired-callback": () => {},
          },
          auth
        );
      }
    };

    initializeRecaptcha();
  }, []);

  useEffect(() => {
    if (user && typeof user === "object") {
      // Navigate when user exists as an object

      if (item?.login === "cartpage") {
        navigate("/Cart");
      } else {
        navigate("/");
      }
    }
  }, [user, navigate]);

  // useEffect(() => {
  //   if(item?.login === 'cartpage'){
  //     navigate('/Cart');

  //     }else{
  //     navigate('/');

  //     }

  // }, [])

  return (
    <>
    <Navbar/>
      <div id="recaptcha-container"></div>

      <div className="login-d1">
        <div className="login-d2">
          <div className="login-d3">
            {user ? (
              <>
                {/* <h1 className="log-my-h1">Login Success</h1> */}
                <div className="log-btns">
                  <button onClick={() => navigate("/Account")}>
                    My Account
                  </button>
                  <button onClick={handleSignOut}>Log Out</button>
                </div>
              </>
            ) : (
              <>
                {showOTP ? (
                  <>
                    <div className="login-d8">
                      <div className="login-d7">
                        <div className="login-d4">
                          <h1>Enter your OTP </h1>
                        </div>
                        <div className="login-d6">
                          <p className="p-tag-opt3">
                            Please enter the OTP sent to your mobile number
                          </p>
                          <div className="ih-c6">
                            <div className="lh-form-in">
                              <label>
                                OTP <span>*</span>
                              </label>
                              <input
                                value={otp}
                                onChange={handleotpNumberChange}
                                type="number"
                                name="cardNumber"
                                required
                                placeholder="One Time Password"
                              />
                            </div>
                          </div>

                          {otp?.length === 6 ? (
                            <div className="get-otp-btn2">
                              <button onClick={onOTPVerify}>
                                {loading ? (
                                  <BeatLoader
                                    size={8}
                                    color={"white"}
                                    loading={loading}
                                  />
                                ) : (
                                  "LOGIN"
                                )}
                              </button>
                            </div>
                          ) : (
                            <div className="get-otp-btn">
                              <button>LOGIN</button>
                            </div>
                          )}

                          <p className="p-tag-otp">
                            By logging in, I confirm that I have read and accept
                            the
                            <span>Terms and Conditions</span> <span></span>
                            and the <span>Privacy Policy.</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="login-d8">
                      <div className="login-d7">
                        <div className="login-d4">
                          <h1>Login / Register </h1>
                        </div>
                        <div className="login-d6">
                          <div className="ih-c6">
                            <div className="lh-form-in">
                              <label>
                                Phone <span>*</span>
                              </label>
                              <input
                                value={ph}
                                onChange={(e) => setPh(e.target.value)}
                                name="cardNumber"
                                required
                                type="text"
                              />
                            </div>

                            <p className="p-tag-otp2">
                              Please enter a phone number in the format
                              +91XXXXXXXXXX.
                            </p>
                          </div>

                          {ph?.length === 13 ? (
                            <div className="get-otp-btn2">
                              <button onClick={onSignup}>
                                {loading ? (
                                  <BeatLoader
                                    size={8}
                                    color={"white"}
                                    loading={loading}
                                  />
                                ) : (
                                  " GET OTP"
                                )}
                              </button>
                            </div>
                          ) : (
                            <div className="get-otp-btn">
                              <button>GET OTP</button>
                            </div>
                          )}

                          <p className="p-tag-otp">
                            An OTP will be sent to your mobile number for
                            verification
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            {/* {showOTP ? (
                    <>
                      <div className="form-log-no">
                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          OTPLength={6}
                          otpType="number"
                          disabled={false}
                          autoFocus
                          className="opt-container "
                        ></OtpInput>

                        <div className="log-continue-btn1">
                          <button onClick={onOTPVerify}>Verify OTP</button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="form-log-no">
                        <div className="react-ph">
                          <PhoneInput
                            country={"in"}
                            value={ph}
                            onChange={setPh}
                          />
                        </div>

                        <div className="log-continue-btn1">
                          <button onClick={onSignup}>Send code via SMS</button>
                        </div>
                      </div>
                    </>
                  )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
