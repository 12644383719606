import React, { useEffect, useMemo, useRef, useState } from "react";

import "./Navbar.css";
import { AiOutlineHeart, AiOutlineMenu, AiOutlineSearch } from "react-icons/ai";
import { BiUser } from "react-icons/bi";
import { HiOutlineShoppingBag } from "react-icons/hi";

import { Link, NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { GrClose } from "react-icons/gr";
import { useSelector } from "react-redux";
import { selectBasketItems } from "../../Redux/basketSlice";
import { auth, db } from "../../Firebase/Firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
const StyledOffCanvas = styled.aside`
  position: fixed;
  top: 0;
  right: ${(props) => (props.isOpen ? 0 : "-100%")};
  width: 300px;
  height: 100%;
  background-color: white;
  z-index: 1000;
  color: white;
  transition: right 0.5s ease-out;
`;

const StyledOffCanvasOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const Navbar = () => {
  const navigate = useNavigate();

  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 200) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const items = useSelector(selectBasketItems);

  const getDatafromLS = () => {
    const data = localStorage.getItem("Wishlist");
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  };
  const [wishlist, setWishlist] = useState(getDatafromLS());

  const [user, setUser] = useState();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getDoc(doc(db, "user", user.uid)).then((docSnap) => {
          if (docSnap.exists()) {
            setUser(docSnap.data());
          } else {
            console.log("No Document Found");
          }
        });
      } else {
        console.log("no user");

        setUser();
      }
    });
  }, []);

  const handleSignOut = () => {
    auth.signOut().catch((error) => alert(error.message));
  };

  const [product, setproduct] = useState([]);

  useEffect(() => {
    const q = collection(db, "Categories");

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setproduct(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);
  const [serach, setSearch] = useState(false);

  const [searchh, setSearchh] = useState("");

  const countries = useMemo(() => {
    if (!searchh) return product;

    return product.filter((item) => {
      return item.PName.toLowerCase().includes(searchh.toLowerCase());
    });
  }, [searchh, product]);

  useEffect(() => {
    if (searchh.length === 0) {
    } else {
      setSearch(true);
    }
  }, [searchh]);

  const open = () => {
    setSearch(!serach);
    setIsOpen(false);
  };

  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setSearch(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const productname = product.filter((item) => item.Name === "Shorts");

  const navigateAndRefresh = (item) => {
    navigate("/Category", { state: { product: item } });
    window.location.reload();

    window.location.reload();
  };

  const [isOpen1, setIsOpen1] = useState(false);

  const toggleDropdown = () => {
    setIsOpen1(!isOpen1);
  };

  return (
    <>
      <div className={showButton?" fix-nav":''}>
        <div>
          <div
            className={`nav-div2 ${
              showButton ? "sticky-nav fadeInDown-nav" : ""
            }`}
          >
            <div
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
              className={` ${showButton ? "logo-nav-black" : "logo-nav"}`}
            >
              <img
                className="logo-nav1"
                src={require("../../../assets/images/logo/banlogo.jpg")}
                alt=""
              />
            </div>

            <div className="log-nav-icons">
              <ul className={` ${showButton ? "ul-black-nav" : "ul-nav"}`}>
                {/* <li className={showButton ? "li-black" : "l1"}>
                  <>Men</>
                </li> */}

                <div className="dropdown-container">
                  <div
                    className="dropdown"
                    onMouseEnter={toggleDropdown}
                    onMouseLeave={toggleDropdown}
                  >
                    <li className={showButton ? "li-black" : "l1"}>
                      <>Men</>
                    </li>
                    {/* <button className="dropdown-button">Dropdown</button> */}
                    {isOpen1 && (
                      <div className="dropdown-content">
                        {Array.isArray(product)
                          ? product.map((item, key) => {
                              return (
                                <li
                                  onClick={() => navigateAndRefresh(item)}
                                  className={'ll2'}
                                >
                                  <span>{item.PName}</span>
                                </li>
                              );
                            })
                          : null}
                      </div>
                    )}
                  </div>
                </div>

                {Array.isArray(productname)
                  ? productname.map((item, key) => {
                      return (
                        <li
                          onClick={() => navigateAndRefresh(item)}
                          className={showButton ? "li-black" : "l1"}
                        >
                          {item.PName}
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>

            <div className={`${showButton ? "search-nav12" : "search-nav11"}`}>
              <AiOutlineSearch
                className="search-svg"
                onClick={() => setSearch(!serach)}
                size={20}
              />
              <input
                className={`${
                  showButton ? "search-input-nav1" : "search-input-nav"
                }`}
                placeholder="Search..."
                type="text"
                name=""
                id=""
                onChange={(e) => setSearchh(e.target.value)}
                value={searchh}
              />
            </div>
            <div
              className={`
            log-nav-icons
              ${
                showButton
                  ? "navbar-right-h-icons-black"
                  : "navbar-right-h-icons"
              }`}
            >
              <Link
                to={"/Wishlist"}
                className={showButton ? "nav-links" : "nav-links-black"}
              >
                <div className="cart-count-nav">
                  <AiOutlineHeart size={22} />
                  {/* <span>({wishlist.length})</span> */}
                </div>
              </Link>
              <div className="mb-icons-nav">
                <Link
                  to={"/Account"}
                  className={showButton ? "nav-links" : "nav-links-black"}
                >
                  <BiUser size={22} />
                </Link>
              </div>
              <Link
                to={"/Cart"}
                className={showButton ? "nav-links" : "nav-links-black"}
              >
                <div className="cart-count-nav">
                  <HiOutlineShoppingBag size={22} />({items.length})
                </div>
              </Link>
            </div>
            <div className="log-nav-icons">
              {user ? (
                <>
                  <div className="right-h-user">
                    <Link
                      to={"/Account"}
                      className={showButton ? "nav-links" : "nav-links-black"}
                    >
                      <BiUser size={22} />
                    </Link>
                    <Link to={"/Account"}>
                      <h1
                        className={
                          showButton ? "user-name-nav-black" : "user-name-nav"
                        }
                      >
                        {user?.Phone}
                      </h1>
                    </Link>
                    <button
                      className="btn-log-out-nav"
                      onClick={() => handleSignOut()}
                    >
                      Sign out
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <ul className={` ${showButton ? "ul-black-nav" : "ul-nav"}`}>
                    <li className={showButton ? "li-black" : "l1"}>
                      <Link to={"/Signin"}>Sign In</Link>
                    </li>

                 
                  </ul>
                </>
              )}
            </div>
            <div className="mb-icons-nav">
              <div className="mb-icons-nav1">
                <Link
                  to={"/Wishlist"}
                  className={showButton ? "nav-links" : "nav-links-black"}
                >
                  <AiOutlineHeart size={22} />
                </Link>
                <Link
                  to={"/Cart"}
                  className={showButton ? "nav-links" : "nav-links-black"}
                >
                  <HiOutlineShoppingBag size={22} />
                </Link>
                <span
                  onClick={() => setIsOpen(true)}
                  className={showButton ? "nav-links" : "nav-links-black"}
                >
                  <AiOutlineMenu size={22} />
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* {serach && ( */}
        <div className="head-dd">
          {serach && (
            <>
              <div ref={wrapperRef} className="op-n-d1  fadeInDown-nav">
                <div className="op-n-d2">
                  <div className="on-c-op">
                    <div className="logo-c-op">
                      <h1 className="logo-h1-n1">Bansport</h1>
                    </div>
                    <div className="search-c-op2">
                      <div className={"search-nav-gray2"}>
                        <AiOutlineSearch color="#80808063" size={20} />
                        <input
                          className={"search-input-nav2"}
                          placeholder="Search..."
                          type="text"
                          name=""
                          id=""
                          onChange={(e) => setSearchh(e.target.value)}
                          value={searchh}
                        />
                      </div>
                    </div>
                    <div className="cancel-c-op">
                      <button onClick={() => setSearch(!serach)}>Cancel</button>
                    </div>
                  </div>
                  <div className="on-c-op2">
                    <div className="logo-c-op">
                      <h1 className="logo-h1-n2">Popular Search Terms</h1>
                    </div>
                    <div className="category-names-div">
                      {Array.isArray(product)
                        ? countries.map((item, key) => {
                            return (
                              <>
                                <h1
                                  onClick={() =>
                                    navigate("/Category", {
                                      state: { product: item },
                                    })
                                  }
                                  className="category-names-nav"
                                >
                                  {item.PName}
                                </h1>
                              </>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div v className={isOpen ? "none-f-sidenav" : "none-d-sidenav"}>
        <StyledOffCanvas isOpen={isOpen}>
          <div className={isOpen ? "head-mobile-menu" : "none-d-sidenav"}>
            <div className="close-icon-nav">
              <GrClose
                color="black"
                size={20}
                onClick={() => setIsOpen(false)}
              />
            </div>
            <div>
              <AiOutlineSearch onClick={open} color="black" size={20} />
            </div>
            <div className="">
              <ul className={"side-bar-menu-ul"}>
                {user ? (
                  <>
                    {" "}
                    <div className="right-h-user1">
                      <Link to={"/Account"} className="right-h-user">
                        <BiUser color="black" size={22} />
                        <h1 className={"user-name-nav-black"}>{user?.name}</h1>
                      </Link>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <li className={""}>
                  <Link to={"/Category"}>Men</Link>
                </li>

                <li className={""}>
                  <Link to={"/Category"}>Men</Link>
                </li>
              </ul>
              <div className="side-nav-btn-div">
                {user ? (
                  <>
                    <button
                      className="btn-side-nav1"
                      onClick={() => handleSignOut()}
                    >
                      Sign out
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn-side-nav1"
                      onClick={() => navigate("/Signin")}
                    >
                      Sign in
                    </button>
                  
                  </>
                )}
              </div>
            </div>
          </div>
        </StyledOffCanvas>
        <StyledOffCanvasOverlay
          isOpen={isOpen}
          onClick={() => setIsOpen(false)}
        />
      </div>
    </>
  );
};

export default Navbar;
