import React, { useEffect, useState } from "react";
import "./Product.css";

import Carouselimg from "./Carouselimg";
import { IoStatsChartOutline } from "react-icons/io5";

import { BiSolidShoppingBag } from "react-icons/bi";
import { AiOutlineHeart } from "react-icons/ai";
import RelatedSwiper from "./RelatedSwiper";
import { useLocation, useNavigate, useRouteError } from "react-router-dom";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../components/Firebase/Firebase";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { toast } from "react-hot-toast";
import ScrollContainer from "react-indiana-drag-scroll";
import Currency from "react-currency-formatter";

import { Carousel } from "react-responsive-carousel";
import { useDispatch, useSelector } from "react-redux";
import {
  addToBasket,
  removeFromBasket,
  selectBasketItemsWithId,
} from "../../components/Redux/basketSlice";
import styled from "styled-components";
import { GrClose } from "react-icons/gr";
import { BeatLoader } from "react-spinners";
import ImageZoom from "react-image-zooom";
import Navbar from "../../components/Layout/Navbar/Navbar";

const StyledOffCanvas = styled.aside`
  position: fixed;
  width: 300px;
  top: 0;
  right: ${(props) => (props.isOpen ? 0 : "-100%")};
  height: 100%;
  background-color: white;
  z-index: 1000;
  color: white;
  transition: right 0.5s ease-out;
`;

const StyledOffCanvasOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const getDatafromLS = () => {
  const data = localStorage.getItem("Wishlist");
  if (data) {
    return JSON.parse(data);
  } else {
    return [];
  }
};

const Product = () => {
  const [selectcolor, setColor] = useState("");
  const selectedcolor = (option) => {
    setColor(option);
  };

  const [selectsize, setselectsize] = useState("");
  const selectedSize = (option) => {
    setselectsize(option);
  };
  const navigate = useNavigate();

  const location = useLocation();
  const {
    id,
    image,
    name,
    price,
    description,
    available,
    descriptionss,
    sizeChart,
    category,
    type,
  } = location.state;

  const [psize, setPsize] = useState([]);

  useEffect(() => {
    const todosref = collection(db, "Products", type, category, id, "Size");
    const q = query(todosref, orderBy("num", "asc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setPsize(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [pcolor, setPcolor] = useState([]);

  useEffect(() => {
    const todosref = collection(db, "Products", type, category, id, "Color");
    const q = query(todosref, orderBy("num", "asc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setPcolor(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [cimages, setCimages] = useState([]);

  useEffect(() => {
    const todosref = collection(db, "Products", type, category, id, "Images");
    const q = query(todosref, orderBy("num", "asc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setCimages(cities);
      setIsLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const refreshPage = () => {
    window.location.reload();
  };
  // const items = useSelector((state) => selectBasketItemsWithId(state, id));
  const dispatch = useDispatch();

  const addItemToBasket = () => {
    dispatch(
      addToBasket({
        id,
        image,
        name,
        price,
        description,
        available,
        descriptionss,
        selectsize,
        selectcolor,
      })
    );
    toast.success("Add to Bag");


    navigate("/Cart")

  };

  // const removeItemToBasket = () => {
  //   if (!items.length > 0) return;

  //   dispatch(removeFromBasket({ id }));
  // };

  const [size, setSize] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const todosref = collection(db, "sizechart", sizeChart, sizeChart);
    const q = query(todosref, orderBy("num", "asc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setSize(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const selectsizetost = () => {
    toast.error("Select Size");
  };
  const selectcolortost = () => {
    toast.error("Select Color");
  };

  const [Wishlist, setWishlist] = useState(getDatafromLS());

  const handleAddBookSubmit = (e) => {
    // creating an object
    let Wish = {
      id,
      image,
      name,
      price,
      description,
      available,
      descriptionss,
      sizeChart,
      category,
      type,
    };
    setWishlist([...Wishlist, Wish]);
    toast.success("Add to Wishlist");
  };

  useEffect(() => {
    localStorage.setItem("Wishlist", JSON.stringify(Wishlist));
  }, [Wishlist]);

  const [selectedImg, setSelectedImg] = useState(null);

  const selectimg = (item) => {
    setSelectedImg(item);
  };
  console.log(selectedImg);
  return (
    <>
      {isLoading && (
        <div className="loader-sp">
          <BeatLoader size={15} color={"black"} loading={isLoading} />
        </div>
      )}

      <Navbar/>
      <div className="product-div1">
        <div className="product-div2">
          <div className="main-div-carousel">
            {cimages.length === 0 ? (
              <div>
                <ImageZoom
                  src={image}
                  alt="A image to apply the ImageZoom plugin"
                  className="image-zoom-main"
                  zoom="400"
                />
              </div>
            ) : (
              <div className="lf-img-car1">
                {/* <ImageZoom
                  src={selectedImg?.img}
                  alt="A image to apply the ImageZoom plugin"
                  className="image-zoom-main"
                  zoom="200"
                /> */}
                <ImageZoom
                  src={selectedImg ? selectedImg?.img : image}
                  alt="A image to apply the ImageZoom plugin"
                  className="image-zoom-main"
                  zoom="400"
                />

                {/* <img alt="" src={selectedImg?.img} /> */}

                <div className="thumb-div-p1">
                  {Array.isArray(cimages)
                    ? cimages.map((item, key) => (
                        <img
                          onClick={() => selectimg(item)}
                          key={key} // Assign a unique key when using map
                          alt=""
                          className="thumb-img-product"
                          src={item.img}
                        />
                      ))
                    : null}
                </div>
              </div>
            )}

            <div className="right-h-details-product">
              <div>
                <h1 className="heading-product-r1">{name}</h1>
                <h1 className="price-product-r1">Mrp             <Currency quantity={price} currency="INR" />
</h1>
                <p className="in-tax">Price inclusive of all taxes</p>

                {available === true ? (
                  <>
                    <p className="in-tax av-not-av">Available</p>
                  </>
                ) : (
                  <>
                    <p className="in-tax av-not">Out Of Stock</p>
                  </>
                )}
              </div>
              {pcolor.length === 0 ? (
                <></>
              ) : (
                <>
                  <div className="s-c-div1">
                    <h1 className="av-color">Colors Available</h1>
                    {selectcolor ? (
                      <h1 className="av-color">Selected Color:{selectcolor}</h1>
                    ) : (
                      <></>
                    )}
                    <div className="select-c-div">
                      {Array.isArray(pcolor)
                        ? pcolor.map((item, key) => {
                            return (
                              <>
                                <div
                                  style={{ backgroundColor: item.Color }}
                                  className="color-select"
                                  onClick={() => selectedcolor(item.Color)}
                                ></div>
                              </>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </>
              )}

              <div className="s-c-div1">
                <h1 className="av-size">Select Size</h1>
                {selectsize ? (
                  <>
                    <h1 className="av-color">Selected Size:{selectsize}</h1>
                  </>
                ) : (
                  <></>
                )}
                <div className="ss-pro1">
                  {Array.isArray(psize)
                    ? psize.map((item, key) => {
                        return (
                          <>
                            {item?.Available === true ? (
                              <>
                                <div
                                  onClick={() => selectedSize(item.Size)}
                                  className={
                                    selectsize === item.Size
                                      ? "s-c-size-div2"
                                      : "s-c-size-div"
                                  }
                                >
                                  <span> {item.Size}</span>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })
                    : null}
                </div>

                <div className="chart-div">
                  <IoStatsChartOutline color=" #176d93" />
                  <h1 onClick={() => setIsOpen(true)} className="text-check-s">
                    Check Size Chart
                  </h1>
                </div>
              </div>
              {available === true ? (
                <div className="btn-s-con">
                  {selectsize ? (
                    <>
                      {pcolor.length === 0 ? (
                        <button
                          onClick={addItemToBasket}
                          className="btn-add-to-bag"
                        >
                          <BiSolidShoppingBag size={20} />
                          Add to bag
                        </button>
                      ) : (
                        <>
                          {selectcolor ? (
                            <button
                              onClick={addItemToBasket}
                              className="btn-add-to-bag"
                            >
                              <BiSolidShoppingBag size={20} />
                              Add to bag
                            </button>
                          ) : (
                            <button
                              onClick={selectcolortost}
                              className="btn-add-to-bag"
                            >
                              <BiSolidShoppingBag size={20} />
                              Select Color
                            </button>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <button onClick={selectsizetost} className="btn-add-to-bag">
                      <BiSolidShoppingBag size={20} />
                      Select Size
                    </button>
                  )}

                  <p>HANDPICKED STYLES | ASSURED QUALITY</p>
                  <button
                    onClick={() => handleAddBookSubmit()}
                    className="btn-add-to-wish"
                  >
                    <AiOutlineHeart size={20} />
                    Save To wishlist
                  </button>
                </div>
              ) : (
                <div className="btn-s-con">
                  <button className="btn-add-to-bag">
                    <BiSolidShoppingBag size={20} />
                    Out of stock
                  </button>
                  <p>HANDPICKED STYLES | ASSURED QUALITY</p>
                </div>
              )}
            </div>
          </div>

          <div className="details-centent-a1">
            <div className="details-centent-a2">
              {description ? (
                <div className="product-description">
                  <div className="s-c-div2">
                    <div className="head-c-decs">
                      <span className="line-texts"></span>
                      <h1 className="pro-description-h1">
                        Product Description
                      </h1>
                      <san className="line-texts"></san>
                    </div>
                    <p className="details-para1">
                      {description}
                      {descriptionss}
                    </p>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="product-description">
                <div className="s-c-div2">
                  <div className="head-c-decs">
                    <span className="line-texts"></span>
                    <h1 className="pro-description-h1">Returns & Refund</h1>
                    <span className="line-texts"></span>
                  </div>
                  <p className="details-para1">
                    Easy 15 days return and exchange. Return Policies may vary
                    based on products and promotions. For full details on our
                    Returns Policies, please
                  </p>
                </div>
              </div>
              <div className="product-description">
                <div className="s-c-div2">
                  <div className="head-c-decs">
                    <span className="line-texts"></span>
                    <h1 className="pro-description-h1">Related Products</h1>
                    <span className="line-texts"></span>
                  </div>
                </div>
              </div>
              <ScrollContainer>
                <RelatedSwiper refreshPage={refreshPage} category={category} />
              </ScrollContainer>
            </div>
          </div>
        </div>
      </div>

      <StyledOffCanvas isOpen={isOpen}>
        <>
          <div className="side-cart-show">
            <div className="close-icon-nav">
              <GrClose
                color="black"
                size={20}
                onClick={() => setIsOpen(false)}
              />
            </div>

            <table className="table">
              <thead>
                {Array.isArray(size)
                  ? size.map((item, key) => {
                      return (
                        <>
                          <tr>
                            <th>{item.Size}</th>
                            <th>{item.Chest}</th>
                            {item.BodyLength ? (
                              <th>{item.BodyLength}</th>
                            ) : (
                              <></>
                            )}
                          </tr>
                        </>
                      );
                    })
                  : null}
              </thead>
            </table>
          </div>
        </>
      </StyledOffCanvas>
      <StyledOffCanvasOverlay
        isOpen={isOpen}
        onClick={() => setIsOpen(false)}
      />
    </>
  );
};

export default Product;
