import React, { useEffect, useState } from "react";
import "./Scrolltop.css";
import { FiChevronUp } from "react-icons/fi";
function ScrollToTop() {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 500) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
    {showButton ?<>
      <div
        onClick={scrollToTop}
        className={`  ${showButton ? "show fadeInRight scroll-to-top" : ""}`}
      >
        <FiChevronUp  className="svg-scroll-top"   />
      </div>
    </> :<></>}
    
    </>
  );
}

export default ScrollToTop;
