/* eslint-disable react-hooks/exhaustive-deps */
import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../components/Firebase/Firebase";
import "./Account.css";
import { Link, useNavigate } from "react-router-dom";
import { BiChevronRight, BiEdit } from "react-icons/bi";
import { AiOutlineUser } from "react-icons/ai";
import { HiOutlineLogout } from "react-icons/hi";
import { BsFillBasketFill } from "react-icons/bs";
import { toast } from "react-hot-toast";
// import { BeatLoader } from "react-spinners";
import Currency from "react-currency-formatter";
import moment from "moment/moment";
import Navbar from "../../components/Layout/Navbar/Navbar";

// import moment from "moment";

const Account = () => {
  const [selectedState, setSelectedState] = useState("");
  const navigate = useNavigate();

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const navigation = useNavigate();

  const [selectedState1, setSelectedState1] = useState("");

  const handleStateChange1 = (e) => {
    setSelectedState1(e.target.value);
  };

  // An array containing all Indian states
  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
  ];

  const [tab, setTab] = useState("tab1");
  const [addresstab, setAddresstab] = useState(false);
  const [user] = useAuthState(auth);
  const [userID] = [user];

  const [name, setName] = useState();

  const [phone, setPhone] = useState('+91');
  const [pincode, setPincode] = useState();
  const [address, setAddress] = useState();
  const [email, setEmail] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [house, setHouse] = useState();

  const sendform = (e) => {
    if (
      !email ||
      !name ||
      !pincode ||
      !address ||
      !city ||
      !house ||
      !selectedState ||
      !phone
    ) {
      toast.error("Please Fill All Fields");
    } else {
      e.preventDefault();

      addDoc(collection(db, "user", userID?.uid,"Address"), {
        name,
        phone,
        pincode,
        address,
        city,
        state: selectedState,
        house,
        email,
        createdAt: serverTimestamp(),
      }).then((docRef) => {
        const washingtonRef = doc(db,"user", userID?.uid);
        updateDoc(washingtonRef, {
          defaultAddressid: docRef?.id,
        });

        setName("");
        setPhone("");
        setPincode("");
        setAddress("");

        setHouse("");
        setCity("");
        setEmail("");
        setTab("tab1");
        setAddresstab(false)

      });

      toast.success("Address add successfully");
    }
  };

  // const sendform = (e) => {
  //   if (
  //     !email ||
  //     !name ||
  //     !pincode ||
  //     !address ||
  //     !city ||
  //     !house ||
  //     !state ||
  //     !phone
  //   ) {
  //     toast.error("Please Fill All Fields");
  //   } else {
  //     e.preventDefault();

  //     addDoc(collection(db, "user", userID?.uid, "Address"), {
  //       name,
  //       phone,
  //       pincode,
  //       address,
  //       city,
  //       state,
  //       house,
  //       email,

  //       createdAt: serverTimestamp(),
  //     }).then((docRef) => {
  //       const washingtonRef = doc(db, "user", userID?.uid);
  //       updateDoc(washingtonRef, {
  //         defaultAddressid: docRef?.id,
  //       });

  //       setName("");
  //       setPhone("");
  //       setPincode("");
  //       setAddress("");
  //       setState("");
  //       setHouse("");

  //       setCity("");
  //       setEmail("");
  //     });
  //     setAddresstab(false);
  //     toast.success("Address add successfully");
  //   }
  // };

  const [addresss, setAddresss] = useState();

  useEffect(() => {
    try {
      const q = collection(db, "user", userID?.uid, "Address");

      onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });
        setAddresss(cities);
      });
    } catch {}
  }, [userID?.uid]);

  const [orders, setOrders] = useState();

  useEffect(() => {
    try {
      const q = collection(db, "user", userID?.uid, "Orders");

      onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });
        setOrders(cities);
      });
    } catch {}
  }, [userID?.uid]);



  const [select, setSelect] = useState();

  const choose = (option) => {
    setSelect(option);

    toast.success("Please Fill All Fields");
    setTab("tab3");
  };

  useEffect(() => {
    if (select) {
      setName1(select.name);
      setEmail1(select.email);
      setPhone1(select.phone);
      setPincode1(select.pincode);
      setState1(select.state);
      setPincode1(select.pincode);
      setCity1(select.city);
      setAddress1(select.address);
      setHouse1(select.house);
      setSelectedState1(select.state);
    }
  }, [select]);

  const [name1, setName1] = useState();
  const [phone1, setPhone1] = useState();
  const [pincode1, setPincode1] = useState();
  const [address1, setAddress1] = useState();
  const [email1, setEmail1] = useState();
  const [city1, setCity1] = useState();
  const [state1, setState1] = useState();
  const [house1, setHouse1] = useState();

  const updateform = async (item) => {
    if (
      !email1 ||
      !name1 ||
      !pincode1 ||
      !address1 ||
      !selectedState1 ||
      !city1 ||
      !state1 ||
      !house1 ||
      !phone1
    ) {
      toast.error("Please Fill All Fields");
    } else {
      const washingtonRef = doc(db, "user", userID?.uid, "Address", select?.id);
      await updateDoc(washingtonRef, {
        name: name1,
        phone: phone1,
        pincode: pincode1,
        address: address1,
        city: city1,
        state: selectedState1,
        house: house1,
        email: email1,

        createdAt: serverTimestamp(),
      }).then(() => {
        setName1("");
        setPhone1("");
        setPincode1("");
        setAddress("");

        setHouse1("");
        setCity1("");
        setEmail1("");
        setTab("tab1");
      });
      toast.success("Address updated successfully");
    }
  };
  // useEffect(() => {
  //   if (user && typeof user === "object") {
  //     // Navigate when user exists as an object
  //   } else {
  //     // navigate("/");
  //   }
  // }, [user, navigate]);


  const handleSignOut = () => {
    auth.signOut().catch((error) => alert(error.message));
  };



  return (
    <>
    <Navbar/>
      <div className={user? 'main-d-profile'  :   "main-d-profile12"}>
        <div className="pro-d1">
          <div className="pro-d2">
            {user ? (
              <div className="pro-c-1">
                {/* {userID ? (
                <> */}
                  <div className="lh-profile">
                  <div className="check-uppr  upp-ch">
                    <Link to={"/"}>
                      <h1 className="check-h1">HomePage</h1>
                    </Link>
                    <BiChevronRight color=" black" size={25} />
                    <h1 className="check-h2">My account</h1>
                  </div>
                  <div className="lh-d1-pro" onClick={() => setTab("tab1")}>
                    <h1 className="lh-pro-h1">Address</h1>
                    <AiOutlineUser />
                  </div>
                  <div className="lh-d1-pro1" onClick={() => setTab("tab2")}>
                    <h1 className="lh-pro-h1">Orders</h1>
                    <BsFillBasketFill />
                  </div>
                  <div onClick={handleSignOut} className="lh-d1-pro1">
                    <h1 className="lh-pro-h1">Logout</h1>
                    <HiOutlineLogout />
                  </div>
                </div>
                <div className="rh-profile">
                  <div>
                    {tab === "tab1" && (
                      <div>
                        <div className="rh-d-2">
                          <h1 className="add-details-rh">Address details</h1>
                          {addresss?.length === 0 ? (
                            <BiEdit
                              color=" black"
                              onClick={() => setAddresstab(!addresstab)}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="details-add-lf">
                          {addresstab ? (
                            <>
                              <div
                                // onSubmit={handleSubmit}
                                className="form-container"
                              >
                                <div className="check-form-d1">
                                  <div className="form-group">
                                    <label className="check-label">
                                      Name
                                      <span style={{ color: "black" }}>*</span>
                                    </label>
                                    <input
                                      className="input-check"
                                      type="text"
                                      id="name"
                                      name="name"
                                      onChange={(e) => setName(e.target.value)}
                                      value={name}
                                      // onChange={handleInputChange}
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label className="check-label">
                                      Email{" "}
                                      <span style={{ color: "black" }}>*</span>
                                    </label>
                                    <input
                                      className="input-check"
                                      type="email"
                                      id="email"
                                      name="email"
                                      onChange={(e) => setEmail(e.target.value)}
                                      value={email}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="check-label">
                                    Contact No{" "}
                                    <span style={{ color: "black" }}>*</span>
                                  </label>
                                  <input
                                    className="input-check"
                                    type="text"
                                    onChange={(e) => setPhone(e.target.value)}
                                    value={phone}
                                    required
                                  />
                                </div>
                                <div className="check-form-d1">
                                  <div className="form-group">
                                    <label className="check-label">
                                      Address{" "}
                                      <span style={{ color: "black" }}>*</span>
                                    </label>
                                    <input
                                      className="input-check"
                                      type="text"
                                      onChange={(e) =>
                                        setAddress(e.target.value)
                                      }
                                      value={address}
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label className="check-label">
                                      House / Flat No
                                      <span style={{ color: "black" }}>*</span>
                                    </label>
                                    <input
                                      className="input-check"
                                      type="text"
                                      onChange={(e) => setHouse(e.target.value)}
                                      value={house}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="check-form-d1">
                                  <div className="form-group">
                                    <label className="check-label">
                                      Town/City{" "}
                                      <span style={{ color: "black" }}>*</span>
                                    </label>
                                    <input
                                      className="input-check"
                                      type="text"
                                      onChange={(e) => setCity(e.target.value)}
                                      value={city}
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label className="check-label">
                                      Pincode
                                      <span style={{ color: "black" }}>*</span>
                                    </label>
                                    <input
                                      className="input-check"
                                      type="text"
                                      onChange={(e) =>
                                        setPincode(e.target.value)
                                      }
                                      value={pincode}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="ih-c6">
                                  <div className="lh-form-in">
                                    <label>
                                      State <span>*</span>
                                    </label>
                                    <select
                                      id="stateSelect"
                                      value={selectedState}
                                      onChange={handleStateChange}
                                    >
                                      <option value="">Select State</option>
                                      {indianStates.map((state, index) => (
                                        <option key={index} value={state}>
                                          {state}
                                        </option>
                                      ))}
                                    </select>
                                    {/* <p>Selected State: {selectedState}</p>   */}
                                  </div>
                                </div>

                                <button
                                  className="acc-btn-sa"
                                  onClick={sendform}
                                >
                                  Save Address
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              {Array.isArray(addresss)
                                ? addresss.map((item, key) => {
                                    return (
                                      <div className=" lh-cont-pro ">
                                        <div className="add-details-pro">
                                          <h1>{item.name}</h1>
                                          <h1>{item.email}</h1>
                                          <h1>{item.phone}</h1>
                                          <h1>
                                            {" "}
                                            {item.house},{item.address}
                                          </h1>

                                          <h1>{item.pincode}</h1>
                                          <h1>{item.state}</h1>
                                          <h1>{item.city}</h1>
                                        </div>
                                        <BiEdit
                                          color=" black"
                                          onClick={() => choose(item)}
                                        />
                                      </div>
                                    );
                                  })
                                : null}
                            </>
                          )}
                        </div>
                      </div>
                    )}

                    {tab === "tab3" && (
                      <>
                        <div
                          // onSubmit={handleSubmit}
                          className="form-container"
                        >
                          <div className="check-form-d1">
                            <div className="form-group">
                              <label className="check-label">
                                Name
                                <span style={{ color: "black" }}>*</span>
                              </label>
                              <input
                                className="input-check"
                                type="text"
                                id="name"
                                name="name"
                                onChange={(e) => setName1(e.target.value)}
                                value={name1}
                                // onChange={handleInputChange}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label className="check-label">
                                Email <span style={{ color: "black" }}>*</span>
                              </label>
                              <input
                                className="input-check"
                                type="email"
                                id="email"
                                name="email"
                                onChange={(e) => setEmail1(e.target.value)}
                                value={email1}
                                required
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="check-label">
                              Contact No{" "}
                              <span style={{ color: "black" }}>*</span>
                            </label>
                            <input
                              className="input-check"
                              type="text"
                              onChange={(e) => setPhone1(e.target.value)}
                              value={phone1}
                              required
                            />
                          </div>
                          <div className="check-form-d1">
                            <div className="form-group">
                              <label className="check-label">
                                Address{" "}
                                <span style={{ color: "black" }}>*</span>
                              </label>
                              <input
                                className="input-check"
                                type="text"
                                onChange={(e) => setAddress1(e.target.value)}
                                value={address1}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label className="check-label">
                                House / Flat No
                                <span style={{ color: "black" }}>*</span>
                              </label>
                              <input
                                className="input-check"
                                type="text"
                                onChange={(e) => setHouse1(e.target.value)}
                                value={house1}
                                required
                              />
                            </div>
                          </div>

                          <div className="check-form-d1">
                            <div className="form-group">
                              <label className="check-label">
                                Town/City{" "}
                                <span style={{ color: "black" }}>*</span>
                              </label>
                              <input
                                className="input-check"
                                type="text"
                                onChange={(e) => setCity1(e.target.value)}
                                value={city1}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label className="check-label">
                                Pincode{" "}
                                <span style={{ color: "black" }}>*</span>
                              </label>
                              <input
                                className="input-check"
                                type="text"
                                onChange={(e) => setPincode1(e.target.value)}
                                value={pincode1}
                                required
                              />
                            </div>
                          </div>
                          <div className="ih-c6">
                            <div className="lh-form-in">
                              <label>
                                State <span>*</span>
                              </label>
                              <select
                                id="stateSelect"
                                value={selectedState1}
                                onChange={handleStateChange1}
                              >
                                <option value="">Select State</option>
                                {indianStates.map((state, index) => (
                                  <option key={index} value={state}>
                                    {state}
                                  </option>
                                ))}
                              </select>
                              {/* <p>Selected State: {selectedState}</p>   */}
                            </div>
                          </div>

                          <button className="acc-btn-sa" onClick={updateform}>
                            Save Address
                          </button>
                        </div>
                      </>
                    )}

                    {tab === "tab2" && (
                      <>
                        <div className="rh-d-5">
                          <div className="rh-d-d2">
                            <h1 className="add-details-rh">Orders </h1>

                            <h1 className="my-or-dt">
                              My Order
                              ({orders.length})
                            </h1>
                          </div>
                          <div className="order-map-fun">
                            {Array.isArray(orders)
                              ? orders.map((item, key) => {
                                  return (
                                    <>
                                      <div className="order-box1">
                                        <div className="order-d-d2">
                                          <div className="order-d-dd1">
                                            <div className="order-d-dd2">
                                              <div className="order-d-dd3">
                                                <h1>Order Placed</h1>
                                                <h1>
                                                  {item?.createdAt
                                                    ? moment(
                                                        item.createdAt.toDate()
                                                      ).format("MMMM Do YYYY")
                                                    : null}
                                                  13/12/23
                                                </h1>
                                              </div>
                                              <div className="order-d-dd3">
                                                <h1>Total</h1>
                                                <h1>
                                                  <Currency
                                                    quantity={item.ProductPrice}
                                                    currency="INR"
                                                  />
                                                </h1>
                                              </div>
                                            </div>
                                            <div className="order-d-dd4">
                                              <h1>
                                                Order ID:
                                                {/* {item.id} */}123
                                              </h1>

                                              <h1
                                                onClick={() =>
                                                  navigate(
                                                    "/ProductPreview",

                                                    { state: item }
                                                  )
                                                }
                                                style={{
                                                  textDecoration: "underline",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Product Details
                                              </h1>
                                            </div>
                                          </div>
                                          <div></div>
                                        </div>

                                        <div className="order-box-d1">
                                          <div className="order-box-lh">
                                            <img
                                              className="order-box-img-1"
                                              src={item.ProductImage}
                                              alt=""
                                            />
                                            <div className="order-lh-prod">
                                              <h1 className="order-box-h1">
                                                {item.ProductName}
                                              </h1>
                                              <h1 className="order-box-h2">
                                                <Currency
                                                  quantity={item.ProductPrice}
                                                  currency="INR"
                                                />

                                                <div>
                                                  <span>Size:</span>
                                                  <span
                                                    style={{
                                                      textTransform:
                                                        "uppercase",
                                                    }}
                                                  >
                                                    {item.Size}
                                                  </span>
                                                </div>
                                              </h1>
                                            </div>
                                          </div>

                                          <div
                                            onClick={() =>
                                              navigation("/ProductPreview", {
                                                state: item,
                                              })
                                            }
                                          >
                                            <div className="order-box-rh">
                                              <button>Re-Order</button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              

                {/* </> */}
                {/* ) : ( */}
                <>
                  {/* <div className="acc-no-use1">
                    <div className="check-uppr  upp-ch">
                      <Link to={"/"}>
                        <h1 className="check-h1">HomePage</h1>
                      </Link>
                      <BiChevronRight color=" black" size={25} />
                      <h1 className="check-h2">My account</h1>
                    </div>
                    <button
                    style={{color:'black'}}
                      className="btn-sign-in-pro"
                      // onClick={() => navigate("/Login")}
                    >
                      Sign in
                    </button>
                  </div> */}
                </>
                {/* // )} */}
              </div>
            ) : (
              <div>
                <div className="log-btns">
                  <button onClick={() => navigate("/Signin")}>
                    Login First
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
